import React , { useState } from "react";
import PageLayout from "../../layouts/PageLayout";
import { Box, Heading, Anchor } from "../../components/elements";
import { Container, Row, Col } from "react-bootstrap";
import PaymentModal from '../../PaymentModal';
import { createWeb3Modal, defaultConfig, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider, useSwitchNetwork } from '@web3modal/ethers/react';

export default function Shimo() {

  const { open } = useWeb3Modal();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const { switchNetwork } = useSwitchNetwork();

  const [showModal, setShowModal] = useState(false);
  const [priceModal, setPriceModal] = useState(null);
  const [downloadLinkModal, setDownloadLinkModal] = useState(null);

  const handleShow = (link, price) => {
    if(isConnected){
      setPriceModal(price);
      setDownloadLinkModal(link);
      setShowModal(true);
    }else{
      open();
    }
  }
  const handleClose = () => setShowModal(false);

    return (
        <PageLayout>
            <Container fluid>
                <Heading as="h2" className="mc-overview-title">0xTools Platform</Heading>
                    <Row xs={1} sm={2} xl={2}>
                            <Col xs={12} sm={8} xl={8}>
                                <div>
                                    Take your crypto project to the next level! The 0xTools platform gives you the tools to make your project stronger and more reliable.
                                </div>
                                <div>
                                    It helps you build trust with your users and stand out in the blockchain world. With 0xTools, you can create a project that’s powerful, trusted, and ready to succeed!
                                </div>
                                <br/>
                                <div className="text-center">
                                    <h1 style={{color: 'red'}}>Limited Time Offer</h1>
                                </div>
                                <div className="text-center">
                                    <h2 style={{color: 'red'}}>Time's Ticking – Don't Miss Out!</h2>
                                </div>
                                <div className="text-center">
                                    <h1>Standard Version</h1>
                                </div>
                                <br/>
                                <h4>Details : </h4>
                                <div>- Your Users can swap between any 2 tokens</div>
                                <div>- Your token will be the default swap token with BNB</div>
                                <div>- Your Users can create tokens</div>
                                <div>- Your Users can create airdrop websites</div>
                                <div>- Your Users can create launchpad websites</div>
                                <div>- Video tutorials</div>
                                <br/>
                                <h5> BSC Demo : <a href="https://bep20.net/" target="_blank" rel="noreferrer">https://bep20.net</a></h5>
                                <h5> Polygon Demo : <a href="https://ipolygon.net/" target="_blank" rel="noreferrer">https://ipolygon.net</a></h5>
                                <h5> Core Demo : <a href="https://core.0xfactory.com/" target="_blank" rel="noreferrer">https://core.0xfactory.com</a></h5>
                                <br/>
                                <h4>Price : $9.9 ONLY</h4>
                                <br/>
                                <div className="text-center">
                                <Anchor onClick={() => handleShow("https://0xfactory.com/download/zxt/0xTools.zip", 9.9)} rel="noopener noreferrer"><h3 style={{color: 'yellow'}}>Download BSC Version</h3></Anchor>
                                &nbsp;&nbsp;|&nbsp;&nbsp;
                                <Anchor onClick={() => handleShow("https://0xfactory.com/download/zxt/0xTools-Polygon.zip", 9.9)} rel="noopener noreferrer"><h3 style={{color: 'yellow'}}>Download Polygon Version</h3></Anchor>
                                &nbsp;&nbsp;|&nbsp;&nbsp;
                                <Anchor onClick={() => handleShow("https://0xfactory.com/download/zxt/0xTools-Core.zip", 9.9)} rel="noopener noreferrer"><h3 style={{color: 'yellow'}}>Download Core Version</h3></Anchor>
                                </div>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <div className="text-center">
                                    <h1>Premium Version (Earn Fee)</h1>
                                </div>
                                <div className="text-center">
                                    <h2 style={{color: 'yellow'}}>50% Fee Sharing – Achieving Success Together!</h2>
                                </div>
                                <br/>
                                <div>- Earn Swap Fee (no fee or %100 if you have Golden DEX)</div>
                                <div>- Earn Tokens Creation Fee (%50)</div>
                                <div>- Earn Airdrop Creation Fee (%50)</div>
                                <div>- Earn Launchpad Creation Fee (%50)</div>
                                <h5> Demo : <a href="https://bep20.net/" target="_blank" rel="noreferrer">Click here to see live demo</a></h5>
                                <br/>
                                <h4>Price : $200</h4>
                                <br/>
                                <b/>
                                <div className="text-center">
                                <a href="https://t.me/followerzvip" target="_blank" rel="noreferrer"><h3 style={{color: 'yellow'}}>Contact Us</h3></a>
                                </div>
                            </Col>
                            <Col xs={12} sm={4} xl={4}>
                                <Box>
                                    <div className="text-center">
                                        <img src="images/tools.png" width={"100%"} alt="Dex" />
                                    </div>
                                    <br/>
                                </Box>
                            </Col>
                    </Row>
                    <PaymentModal show={showModal} handleClose={handleClose} downloadLink={downloadLinkModal} price={priceModal} />
                </Container>
        </PageLayout>
    )
}
